<template>
  <v-container id="idiomas" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-account-voice"
      inline
      :title="$t('languaje')"
    >
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Idioma</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="red"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/ Editar Idiomas-->
      <v-dialog max-width="500px" v-model="dialog" persistent>
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Idioma -->
                <v-col cols="12">
                  <v-text-field
                    label="Idioma*"
                    required
                    v-model="editedItem.idioma"
                    :error-messages="idiomaErrors"
                    @blur="$v.editedItem.idioma.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.idioma.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Idioma Corto -->
                <v-col cols="12">
                  <v-text-field
                    label="Nombre Corto*"
                    required
                    v-model="editedItem.idioma_corto"
                    :error-messages="idioma_cortoErrors"
                    @blur="$v.editedItem.idioma_corto.$touch()"
                    @change="onTextChange"
                    @input="$v.editedItem.idioma_corto.$reset()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Data Table de Idiomas  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="idiomas"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- Slot de Acciones-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="idiomas" :dataFields="fields" fileName="Idiomas" />
    </base-material-card>
  </v-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  name: 'Idiomas',
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },

  data: () => ({
    //Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    //Arrays
    headers: [
      { text: 'Idioma', align: 'start', sortable: false, value: 'idioma' },
      { text: 'Nombre Corto', value: 'idioma_corto' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    idiomas: [],
    value: [],
    //Objetos
    defaultItem: {
      _id: '',
      idioma: '',
      idioma_corto: '',
      activo: true,
    },
    editedItem: {
      _id: '',
      idioma: '',
      idioma_corto: '',
      activo: true,
    },
    fields: {
      Idioma: 'idioma',
      Clave: 'idioma_corto',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      idioma: { required, minLength: minLength(3), maxLength: maxLength(10) },
      idioma_corto: { required, minLength: minLength(2), maxLength: maxLength(3) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Idioma' : 'Editar Idioma'
    },

    // Propiedad computada para errores en campo Idioma
    idiomaErrors() {
      const errors = []
      if (!this.$v.editedItem.idioma.$dirty) return errors
      !this.$v.editedItem.idioma.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.idioma.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.idioma.maxLength &&
        errors.push('Este campo debe de tener un máximo de 10 caracteres.')
      return errors
    },

    // Propiedad computada para errores en campo Idioma corto
    idioma_cortoErrors() {
      const errors = []
      if (!this.$v.editedItem.idioma_corto.$dirty) return errors
      !this.$v.editedItem.idioma_corto.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.idioma_corto.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.idioma_corto.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerIdiomas()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let body = {
        _id: this.editedItem._id,
      }
      let payload = { body: body }
      payload.id = this.editedItem._id
      this.$store
        .dispatch('languaje/deleteLanguaje', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de idiomas
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de idiomas
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de idiomas
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.idiomas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.idiomas.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar idiomas
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.idiomas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas los idiomas
     */
    async obtenerIdiomas() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('languaje/fetchLanguajes', payload)
        .then((response) => {
          this.idiomas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para un idioma nuevo o
     * la edicion de un idioma
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          idioma: this.editedItem.idioma,
          idioma_corto: this.editedItem.idioma_corto,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          //payload.id = this.editedItem.id
          this.$store
            .dispatch('languaje/editLanguaje', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.idiomas[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('languaje/addLanguaje', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.idiomas.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
